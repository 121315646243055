





























































































import Vue from "vue";
import Component from "vue-class-component";
import ExhibitorResultCard from "@/components/shared/ExhibitorResultCard.vue";
import ExhibitorLogoCard from "@/components/shared/ExhibitorLogoCard.vue";
import { getModule } from "vuex-module-decorators";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";

const tradeshowStore = getModule(tradeshowVuexModule);

@Component({
    components: {
        ExhibitorResultCard,
        ExhibitorLogoCard
    }
})
export default class SolutionCenter extends Vue {
    get titleSponsors() {
        return this.tradeshowCompanies ? this.tradeshowCompanies.title : [];
    }

    get premierSponsors() {
        return this.tradeshowCompanies ? this.tradeshowCompanies.premier : [];
    }

    get partnerSponsors() {
        const companies = this.tradeshowCompanies
            ? this.tradeshowCompanies.partner
            : [];
        return Vue.prototype.MgSortByProperty(companies, "companyName");
    }

    get tradeshowCompanies() {
        return tradeshowStore.tradeshowCompanies;
    }
    get configColors() {
        return this.$store.getters.configColors;
    }

    get getLayoutOptions() {
        return this.$store.getters.layoutOptions;
    }
    async beforeMount() {
        await tradeshowStore.getTradeshowCompanies();
    }
}
